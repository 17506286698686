import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Me from "../../img/Me.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight, faLanguage, faCarSide } from "@fortawesome/free-solid-svg-icons";
import { faReact } from "@fortawesome/free-brands-svg-icons";

function APropos() {
  const [text, setText] = useState("");
  //const [isTyping, setIsTyping] = useState(false);
  const [cursor, setCursor] = useState(true);

  const content = `Born Hugo BARBIERI, I've been passionate about IT for as long as I can remember. 
  Having passed my general baccalaureate last summer, I'm now working on subjects that motivate me, such as web and object-oriented development and HMI, in a course that suits me. 
  During my first year in the Computer Science BUT program at the IUT2 in Grenoble, I was able to develop my project management and development skills, enabling me to work as part of a group of developers to bring an IT project to fruition. 
  My attraction for innovation and my thirst for learning make me the ideal candidate to make a significant contribution to IT projects within a promising company.`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (text.length < content.length) {
        setText(content.substring(0, text.length + 1));
        //setIsTyping(true);
      } else {
        clearInterval(intervalId);
        //setIsTyping(false);
        // Start cursor blinking after typing animation
        const blinkInterval = setInterval(() => {
          setCursor(!cursor);
        }, 500);
        return () => clearInterval(blinkInterval);
      }
    }, 40);

    return () => clearInterval(intervalId);
  }, [content, text, cursor]);

  return (
    <>
      <Helmet>
        <title>About - Portfolio</title>
      </Helmet>
      <div className="h-1 bg-zinc-700"></div>
      <main className="font-Andalemo text-white children:text-base bg-zinc-800 h-[100%] min-h-[87vh] flex flex-col py-7 items-start">
        <div className="">
          <section className="min-h-[80vh] h-full flex flex-col justify-between items-center">
            <div className="self-center border-t-2 w-[60%]"></div>

            <div className=" my-4 flex flex-col md:flex-row items-center gap-4 md:gap-7 z-50 max-w-[85vw] md:max-w-[60vw]">
              <img className="size-40 md:size-46 rounded-full" src={Me} alt="Me" />
              <div className="flex items-start gap-2 relative">
                    <FontAwesomeIcon className="size-7 text-[#67348B]" icon={faAngleDoubleRight} />
                    <div style={{ display: "inline-block" }}>
                        <p className="relative">
                            {text}
                            {/* Add a blinking cursor
                            {isTyping || cursor ? (
                                <span className="absolute top-0 right-0 blink" style={{width: "2px", height: "1em", background: "white"}} />
                            ) : null} */}
                        </p>
                    </div>
                </div>
            </div>
            <div className="self-center border-t-2 w-[60%]"></div>
          </section>

          <section className="h-full flex flex-col justify-start gap-10 items-center relative">
            <h3 className="text-4xl font-Krungthep self-start ml-7 mt-7">My +</h3>

            <div className="grid grid-cols-1 gap-12 z-50 max-w-[85vw] h-full ml-14">
                <div className=" mt-4 flex flex-col-2 gap-4 items-center">
                    <div className="w-12 h-12 bg-zinc-700 rounded-full flex items-center justify-center">
                        <FontAwesomeIcon className="size-7 p-4 text-[#67348B]" icon={faReact} />
                    </div>
                    <p>
                    Fascinated by the world of IT and constantly on the lookout for innovation, I'm driven by the desire to discover and learn new things all the time!
                    </p>
                </div>

                <div className="flex flex-col-2 gap-5 items-center">
                    <div className="w-12 h-12 bg-zinc-700 rounded-full flex items-center justify-center">
                        <FontAwesomeIcon className="size-7 p-4 text-[#67348B]" icon={faLanguage} />
                    </div>
                    <p>
                    With a B2 level in English and a B1 level in Italian, I'm comfortable understanding technical documents and following a conversation in these languages.
                    </p>
                </div>

                <div className="flex flex-col-2 gap-5 items-center">
                    <div className="w-12 h-12 bg-zinc-700 rounded-full flex items-center justify-center">
                        <FontAwesomeIcon className="size-7 p-4 text-[#67348B]" icon={faCarSide} />
                    </div>
                    <p>
                    I hold a category B driving license and have my own vehicle, so I can get around independently.
                    </p>
                </div>
            </div>

            <div className=" mt-7 self-center border-t-2 w-[60%]"></div>
          </section>
        </div>
      </main>
      <div className="h-1 bg-zinc-700"></div>
    </>
  );
}

export default APropos;

// Add styles for the blinking cursor
