import React from "react";
import { Helmet } from "react-helmet";

function Projets(){
    const handleRedirection = (url) => {
        window.open(url, '_blank');
    };

    return <>
    <Helmet>
        <title>Projects - Portfolio</title>
    </Helmet>
    <div className="h-1 bg-zinc-700"></div>
    <main className="font-ChakraPetch text-white bg-zinc-800 h-full flex flex-col gap-10 pt-7">
        <h2 className=" font-bold text-4xl mx-auto">My Projects !</h2>

        <div class="container mx-auto w-full h-full overflow-y-clip overflow-x-hidden mb-10">
            <div class="relative wrap h-full">
                <div class="border-2-2 absolute border-opacity-20 border-zinc-300 h-full border left-[50%]"></div>
                
                <div class=" mb-8 flex justify-between items-center w-full right-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 mr-2 sm:mr-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">This Portfolio</h3>
                        <p class="text-sm leading-snug tracking-wide text-zinc-300 text-opacity-100">My portfolio is a constantly evolving project, designed with the main aim of exploring and learning the ins and outs of React. 
                        Each update aims to improve the user experience, whether by adding new animations or optimizing accessibility to ensure an optimal experience for all.</p>
                    </div>
                </div>

                
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 ml-2 sm:ml-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Student project : SQL</h3>
                        <p class="text-sm font-medium leading-snug tracking-wide text-zinc-300 text-opacity-100">As part of a project at IUT2, I had to clean up an open source database on the nutriscore of foods (OpenFoodFacts), retaining only relevant information on French sweetened beverages. 
                        This process also involved managing any input errors made by contributors to the database. 
                        I then used this data to produce a statistical report explaining the relationship between the nutriscore and the various nutritional values of these drinks.</p>
                    </div>
                </div>
                
            
                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2023</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 mr-2 sm:mr-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Student project : Java</h3>
                        <p class="text-sm leading-snug tracking-wide text-zinc-300 text-opacity-100">As part of the Grenoble IUT2 project, I designed a Java script to classify dispatches into different categories such as sport, culture and politics, analyzing their content to sort them appropriately.</p>
                    </div>
                </div>

                
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2023</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 ml-2 sm:ml-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Website for a craftsman</h3>
                        <p class="text-sm font-medium leading-snug tracking-wide text-zinc-300 text-opacity-100">During my last months of high school, I designed a website in HTML and CSS for a builder. 
                        At the time, my knowledge was limited, and it was my first complete website. 
                        Since then, I've acquired new skills, particularly in React, and my aim now is to rebuild this site using the knowledge I've acquired.
                        <br /> Link : <span className=" cursor-pointer underline underline-offset-2" onClick={() => handleRedirection('https://multi-renovation-belledonne.fr')} >https://multi-renovation-belledonne.fr</span></p>
                        
                    </div>
                </div>
            </div>
        </div>

        <h2 className=" font-bold text-4xl mx-auto">Coming soon...</h2>

        <div class="container mx-auto w-full h-full overflow-y-clip overflow-x-hidden mb-10">
            <div class="relative wrap h-full">
                <div class="border-2-2 absolute border-opacity-20 border-zinc-300 h-full border left-[50%]"></div>
                
                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 mr-2 sm:mr-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Student project : Java FX</h3>
                        <p class="text-sm leading-snug tracking-wide text-zinc-300 text-opacity-100">As part of a future study project, our aim will be to design and develop a JavaFX application designed to assist and support events professionals in managing their activities.</p>
                    </div>
                </div>  
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 ml-2 sm:ml-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Creating a SASU</h3>
                        <p class="text-sm font-medium leading-snug tracking-wide text-zinc-300 text-opacity-100">In a few weeks, my SASU will be up and running (Dive.), enabling me to work as a freelance developer.
                        <br />Link : [coming soon] </p>
                    </div>
                </div>
            </div>
        </div>
        

    </main>
    <div className="h-1 bg-zinc-700"></div>
    </>
}

export default Projets;