import React from "react";
import { Helmet } from "react-helmet";
//import logoCouleur from '../../img/logoCouleur.png';

function Index() {

  return (
    <>
      <Helmet>
        <title>Hugo BARBIERI - Portfolio</title>
      </Helmet>
      <main className="pt-6 md:pt-4 h-[100%] w-[100%] bg-zinc-900 font-ChakraPetch text-white children:hover:cursor-default">
        
        <div className="grid z-10 bg-transparent">
          <section className="h-[78vh] sm:h-[84vh] md:h-[86vh] flex flex-col justify-between">
            <h3 className="text-lg pl-7">Grenoble - France</h3>
            <div className="flex items-center">
              <h3 className="text-lg -rotate-90 ml-1">Portfolio</h3>
              <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-[#E6007E] to-[#67348B] drop-shadow-[0px_0px_5px_rgb(25,25,25)] text-[2.7rem] sm:text-7xl uppercase">Developer</h2>
            </div>
            <h3 className="text-lg pl-7 pb-1">React / Java</h3>
          </section>
        </div>
        
        <div className="h-1 bg-zinc-700"></div>
      </main>
    </>
  );
}

export default Index;
