import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { push as Menu } from 'react-burger-menu';
import '../css/Burger.css'

function Header({ onPageChange, currentPage }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mbHeader, setMbHeader] = useState('0vh');
  const [icon, setIcon] = useState(faBars);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
    setMbHeader(state.isOpen ? 'h-14 ' : 'h-0');
    setIcon(state.isOpen ? faChevronDown : faBars);

  };
  
  const closeMenu = () => {
    setMenuOpen(false);
    setMbHeader('h-0');
    setIcon(faBars);
  };

  return (
    <>
    <header className=" font-ChakraPetch w-[100%] bg-zinc-900 text-white flex justify-between pl-7 pr-7 items-center min-h-[12vh] text-lg">
      <h2 className={` font-Krungthep text-3xl hover:cursor-pointer duration-300 hover:scale-110 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r from-[#E6007E] to-[#67348B] ${currentPage === 'accueil' && 'active'}`} onClick={() => { closeMenu(); onPageChange('accueil')}}>Hugo BARBIERI</h2>
      <nav className='hidden md:flex mr-4'>
        <ul className="flex gap-3">
          <li className={`no-line hover:underline hover:underline-offset-[5px] hover:decoration-2 hover:cursor-pointer duration-300 hover:scale-110 ${currentPage === 'projets' && 'active'}`} onClick={() => onPageChange('projets')}>
            <div className='hover:overline hover:decoration-2 duration-300'>Projets</div> 
          </li>
          <li className='hover:cursor-default'>/</li>
          <li className={`no-line hover:underline hover:underline-offset-[6px] hover:decoration-2 hover:cursor-pointer duration-300 hover:scale-110 ${currentPage === 'a-propos' && 'active'}`} onClick={() => onPageChange('a-propos')}>
            <div className='hover:overline hover:decoration-2 duration-300 min-w-20'>A-propos</div>
          </li>
          <li className='hover:cursor-default'>/</li>
          <li className={`no-line hover:underline hover:underline-offset-[5px] hover:decoration-2 hover:cursor-pointer duration-300 hover:scale-110 ${currentPage === 'contact' && 'active'}`} onClick={() => onPageChange('contact')}>
            <div className='hover:overline hover:decoration-2 duration-300'>Contact</div>
          </li>
          <li className='hover:cursor-default'>|</li>
        </ul>
      </nav>

      <div className='sm:flex md:hidden size-7 gap-2'>
        <Menu
          customBurgerIcon={<FontAwesomeIcon className='hover:cursor-pointer' icon={icon} />}
          width={'100%'}
          isOpen={menuOpen}
          onStateChange={(state) => handleStateChange(state)}
          top
          noOverlay
        >
          <li className={`hover:text-[#67348B] hover:cursor-pointer ${currentPage === 'projets' && 'active'}`} onClick={() => { closeMenu(); onPageChange('projets'); }}>Projets</li>
          <li className={`hover:text-[#67348B] hover:cursor-pointer ${currentPage === 'a-propos' && 'active'}`} onClick={() => { closeMenu(); onPageChange('a-propos'); }}>A-Propos</li>
          <li className={`hover:text-[#67348B] hover:cursor-pointer ${currentPage === 'contact' && 'active'}`} onClick={() => { closeMenu(); onPageChange('contact'); }}>Contact</li>
        </Menu>
      </div>
    </header>
    <div className={`sm:block md:hidden bg-zinc-900 ${mbHeader}`}></div>
    </>
    

  );
}

export default Header;
