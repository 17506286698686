import Marquee from "react-fast-marquee";

function Warning() {
    return <>
    <Marquee 
    speed={120}
    className=" -z-10 h-12 bg-zinc-700 text-white">     
This website is a non-definitive version and is subject to regular modifications.
    </Marquee>
    </>
}

export default Warning;