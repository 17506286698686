import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Me from "../../img/Me.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight, faLanguage, faCarSide } from "@fortawesome/free-solid-svg-icons";
import { faReact } from "@fortawesome/free-brands-svg-icons";

function APropos() {
  const [text, setText] = useState("");
  //const [isTyping, setIsTyping] = useState(false);
  const [cursor, setCursor] = useState(true);

  const content = `Né sous le nom d'Hugo BARBIERI, je suis, depuis aussi longtemps que je me souvienne, passionné par l'informatique. 
    Ayant obtenu mon baccalauréat général l'été dernier, j'exerce à présent des matières qui m'animent comme le développement web et orienté objet et l'IHM dans une formation qui me correspond. 
    Durant ma première année en BUT informatique à l'IUT2 de Grenoble, j'ai pu développer des compétences en gestion de projet et développement me permettant ainsi d'agir au sein d'un groupe de développeur pour mener à bien un projet informatique. 
    Mon attrait pour l'innovation et ma soif d'apprendre font de moi le candidat idéal pour contribuer de manière significative à des projets informatiques au sein d'une entreprise prometteuse.`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (text.length < content.length) {
        setText(content.substring(0, text.length + 1));
        //setIsTyping(true);
      } else {
        clearInterval(intervalId);
        //setIsTyping(false);
        // Start cursor blinking after typing animation
        const blinkInterval = setInterval(() => {
          setCursor(!cursor);
        }, 500);
        return () => clearInterval(blinkInterval);
      }
    }, 40);

    return () => clearInterval(intervalId);
  }, [content, text, cursor]);

  return (
    <>
      <Helmet>
        <title>A-Propos - Portfolio</title>
      </Helmet>
      <div className="h-1 bg-zinc-700"></div>
      <main className="font-Andalemo text-white children:text-base bg-zinc-800 h-[100%] min-h-[87vh] flex flex-col py-7 items-start">
        <div className="">
          <section className="min-h-[80vh] h-full flex flex-col justify-between items-center">
            <div className="self-center border-t-2 w-[60%]"></div>

            <div className=" my-4 flex flex-col md:flex-row items-center gap-4 md:gap-7 z-50 max-w-[85vw] md:max-w-[60vw]">
              <img className="size-40 md:size-46 rounded-full" src={Me} alt="Me" />
              <div className="flex items-start gap-2 relative">
                    <FontAwesomeIcon className="size-7 text-[#67348B]" icon={faAngleDoubleRight} />
                    <div style={{ display: "inline-block" }}>
                        <p className="relative">
                            {text}
                            {/* Add a blinking cursor
                            {isTyping || cursor ? (
                                <span className="absolute top-0 right-0 blink" style={{width: "2px", height: "1em", background: "white"}} />
                            ) : null} */}
                        </p>
                    </div>
                </div>
            </div>
            <div className="self-center border-t-2 w-[60%]"></div>
          </section>

          <section className="h-full flex flex-col justify-start gap-10 items-center relative">
            <h3 className="text-4xl font-Krungthep self-start ml-7 mt-7">Mes +</h3>

            <div className="grid grid-cols-1 gap-12 z-50 max-w-[85vw] h-full ml-14">
                <div className=" mt-4 flex flex-col-2 gap-4 items-center">
                    <div className="w-12 h-12 bg-zinc-700 rounded-full flex items-center justify-center">
                        <FontAwesomeIcon className="size-7 p-4 text-[#67348B]" icon={faReact} />
                    </div>
                    <p>
                        Fasciné par le monde de l'informatique et constamment en quête d'innovation, je suis animé par le désir de découvrir et d'apprendre sans cesse de nouvelles choses !
                    </p>
                </div>

                <div className="flex flex-col-2 gap-5 items-center">
                    <div className="w-12 h-12 bg-zinc-700 rounded-full flex items-center justify-center">
                        <FontAwesomeIcon className="size-7 p-4 text-[#67348B]" icon={faLanguage} />
                    </div>
                    <p>
                        Avec un niveau B2 en anglais et un niveau B1 en italien, je suis à l'aise pour comprendre des documents techniques et pour suivre une conversation dans ces langues.
                    </p>
                </div>

                <div className="flex flex-col-2 gap-5 items-center">
                    <div className="w-12 h-12 bg-zinc-700 rounded-full flex items-center justify-center">
                        <FontAwesomeIcon className="size-7 p-4 text-[#67348B]" icon={faCarSide} />
                    </div>
                    <p>
                        Titulaire d'un permis de conduire de catégorie B et disposant d'un véhicule, je suis autonome dans mes déplacements.
                    </p>
                </div>
            </div>

            <div className=" mt-7 self-center border-t-2 w-[60%]"></div>
          </section>
        </div>
      </main>
      <div className="h-1 bg-zinc-700"></div>
    </>
  );
}

export default APropos;

// Add styles for the blinking cursor
