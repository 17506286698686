import React from "react";
import { Helmet } from "react-helmet";

function Projets(){
    const handleRedirection = (url) => {
        window.open(url, '_blank');
    };

    return <>
    <Helmet>
        <title>Projets - Portfolio</title>
    </Helmet>
    <div className="h-1 bg-zinc-700"></div>
    <main className="font-ChakraPetch text-white bg-zinc-800 h-full flex flex-col gap-10 pt-7">
        <h2 className=" font-bold text-4xl mx-auto">Mes Projets !</h2>

        <div class="container mx-auto w-full h-full overflow-y-clip overflow-x-hidden mb-10">
            <div class="relative wrap h-full">
                <div class="border-2-2 absolute border-opacity-20 border-zinc-300 h-full border left-[50%]"></div>
                
                <div class=" mb-8 flex justify-between items-center w-full right-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 mr-2 sm:mr-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Ce Portfolio</h3>
                        <p class="text-sm leading-snug tracking-wide text-zinc-300 text-opacity-100">Mon portfolio est un projet en constante évolution, conçu avec l'objectif principal d'explorer et d'apprendre les tenants et aboutissants de React. 
                        Chaque mise à jour vise à améliorer l'expérience utilisateur, que ce soit par l'ajout de nouvelles animations ou par l'optimisation de l'accessibilité pour garantir une expérience optimale pour tous.</p>
                    </div>
                </div>

                
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 ml-2 sm:ml-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Projet étudiant : SQL</h3>
                        <p class="text-sm font-medium leading-snug tracking-wide text-zinc-300 text-opacity-100">Dans le cadre d'un projet à l'IUT2, j'ai dû nettoyer une base de données open source portant sur le nutriscore des aliments (OpenFoodFacts), en ne conservant que les informations pertinentes concernant les boissons sucrées françaises. 
                        Ce processus a également impliqué la gestion des éventuelles erreurs de saisie commises par les contributeurs à la base de données. 
                        Par la suite, j'ai utilisé ces données pour élaborer un rapport statistique expliquant la relation entre le nutriscore et les différentes valeurs nutritionnelles de ces boissons. </p>
                    </div>
                </div>
                
            
                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2023</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 mr-2 sm:mr-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Projet étudiant : Java</h3>
                        <p class="text-sm leading-snug tracking-wide text-zinc-300 text-opacity-100">Au sein du projet de l'IUT2 de Grenoble, j'ai conçu un script en Java visant à classer des dépêches en différentes catégories telles que le sport, la culture et la politique, en analysant leur contenu pour les trier de manière appropriée.</p>
                    </div>
                </div>

                
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2023</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 ml-2 sm:ml-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Site pour un artisan</h3>
                        <p class="text-sm font-medium leading-snug tracking-wide text-zinc-300 text-opacity-100">Durant mes derniers mois de Terminale, j'ai réalisé un site web en HTML et CSS pour un artisan du bâtiment. 
                        À cette époque, mes connaissances plus limitées qu'à ce jour, et il s'agissait de mon premier site web complet. 
                        Depuis, j'ai acquis de nouvelles compétences, notamment en React, et mon objectif est maintenant de reconstruire ce site en utilisant les nouvelles connaissances que j'ai pu acquérir.
                        <br /> Lien : <span className=" cursor-pointer underline underline-offset-2" onClick={() => handleRedirection('https://multi-renovation-belledonne.fr')} >https://multi-renovation-belledonne.fr</span></p>
                        
                    </div>
                </div>
            </div>
        </div>

        <h2 className=" font-bold text-4xl mx-auto">À venir...</h2>

        <div class="container mx-auto w-full h-full overflow-y-clip overflow-x-hidden mb-10">
            <div class="relative wrap h-full">
                <div class="border-2-2 absolute border-opacity-20 border-zinc-300 h-full border left-[50%]"></div>
                
                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 mr-2 sm:mr-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Projet étudiant : Java FX</h3>
                        <p class="text-sm leading-snug tracking-wide text-zinc-300 text-opacity-100">Dans le cadre d'un futur projet d'études, notre objectif sera de concevoir et développer une application en JavaFX visant à assister et soutenir les professionnels de l'événementiel dans la gestion de leurs activités.</p>
                    </div>
                </div>  

                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div class="order-1 w-5/12"></div>
                    <div class="z-20 flex items-center order-1 bg-gradient-to-r from-[#E6007E] to-[#67348B] shadow-xl w-16 h-16 rounded-full mx-1">
                        <h1 class="mx-auto font-semibold text-lg">2024</h1>
                    </div>
                    <div class="order-1 bg-zinc-700 rounded-lg shadow-xl w-5/12 px-6 py-4 ml-2 sm:ml-0">
                        <h3 class="mb-3 font-Krungthep font-bold text-xl">Création d'une SASU</h3>
                        <p class="text-sm font-medium leading-snug tracking-wide text-zinc-300 text-opacity-100">Dans quelques semaines, ma SASU sera opérationnelle (Dive.), me permettant ainsi d'exercer en tant que développeur freelance.
                        <br />Lien : [à venir] </p>
                    </div>
                </div>
            </div>
        </div>
        

    </main>
    <div className="h-1 bg-zinc-700"></div>
    </>
}

export default Projets;