import React from "react";
import { Helmet } from "react-helmet";
//import logoCouleur from '../../img/logoCouleur.png';

function Index() {

  return (
    <>
      <Helmet>
        <title>Hugo BARBIERI - Portfolio</title>
      </Helmet>
      <main className="pt-6 md:pt-4 h-full w-[100%] bg-zinc-900 font-ChakraPetch text-white children:hover:cursor-default">
        
        <div className="grid z-10 bg-transparent">
          <section className="h-[78vh] sm:h-[84vh] md:h-[86vh] flex flex-col justify-between">
            <h3 className="text-lg pl-7">Grenoble - France</h3>
            <div className="flex items-center">
              <h3 className="text-lg -rotate-90 ml-1">Portfolio</h3>
              <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-[#E6007E] to-[#67348B] drop-shadow-[0px_0px_5px_rgb(25,25,25)] text-[2.7rem] sm:text-7xl uppercase">Developpeur</h2>
            </div>
            <h3 className="text-lg pl-7 pb-1">React / Java</h3>
          </section>
        </div>
        {/*
        <div className="h-1 bg-zinc-700"></div>
        <section className="h-[100%] min-h-[100vh] text-white bg-zinc-800 flex flex-col p-4 items-center justify-between">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl">Qui-suis je ?</h2>
            <span className="px-1 mr-2 mt-2 bg-gradient-to-r from-[#67348B] to-[#E6007E]">En savoir plus sur moi</span>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl">Mes projets</h2>
            <span className="px-1 mt-2 bg-gradient-to-r from-[#67348B] to-[#E6007E]">Découvrez mes principaux projets</span>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl">Me contacter</h2>
            <span className="px-1 mt-2 bg-gradient-to-r from-[#67348B] to-[#E6007E]">Intéressé(e) ? Contactez-moi !</span>
            <div className="ml-12gap-4 flex justify-center items-center w-[25vw] mt-4">
              <div className=" flex flex-col">
                <li>Par mail</li>
                <li>Sur linkedin</li>
                <li>Sur Github</li>
              </div>
              <div className=" border-x-[1px] h-20"></div>
              <button>Accèder au formulaire</button>
            </div>
          </div>
  </section>*/}
        <div className="h-1 bg-zinc-700"></div>
      </main>
    </>
  );
}

export default Index;
