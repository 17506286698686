import Marquee from "react-fast-marquee";

function Warning() {
    return <>
    <Marquee 
    speed={120}
    className=" -z-10 h-12 bg-zinc-700 text-white">
        Ce site web est une version non-définitive qui subit régulièrement des modifications.</Marquee>
    </>
}

export default Warning;