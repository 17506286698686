import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";

function ScrollToTheTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      setIsVisible(window.scrollY > 0);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      {isVisible && (
        <button
          className="font-ChakraPetch text-white fixed duration-300 bottom-5 right-5 rounded bg-zinc-600 hover:bg-gradient-to-r from-[#E6007E] to-[#67348B] z-50"
          onClick={handleScrollToTop}
        >
          <FontAwesomeIcon
            className="size-7 p-2 self-center align-middle"
            icon={faChevronUp}
          />
        </button>
      )}
    </>
  );
}

export default ScrollToTheTop;