import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import '../../css/Contact.css';

function Contact({ onPageChange }) {
    const [nom, setNom] = useState("");
    const [mail, setMail] = useState("");
    const [objet, setObjet] = useState("");
    const [adresse, setAdresse] = useState("");
    const [message, setMessage] = useState("");

    const handleRedirection = (url) => {
        window.open(url, '_blank');
    };

    function sendEmail(event) {
        event.preventDefault();
        
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: "hugobarbieri38@gmail.com",
            Password: "4004807FAD0C965DF3B030CEBA0A28CFAE56",
            To: 'hugobarbieri38@gmail.com',
            From: 'hugobarbieri38@gmail.com',
            Subject: objet,
            Body: `
            Nom: ${nom} \n
            Mail: ${mail} \n
            Objet: ${objet} \n
            Adresse : ${adresse} \n
            Message:\n ${message + " FORMPORTFOLIOHUGO"}
            `
        }).then(
            onPageChange('validation')
        ).catch(
            error => alert("Failed to send email: " + error.message)
        );
    };

    return (
        <>
            <Helmet>
                <title>Contact - Portfolio</title>
            </Helmet>
            <div className="h-1 bg-zinc-700"></div>
            <main className="font-ChakraPetch text-white bg-transparent children:z-50 w-full h-full flex flex-col gap-10 pt-7">
                <div className="background h-full">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <h2 className="text-4xl bg-clip-text text-transparent bg-gradient-to-r from-[#E6007E] to-[#67348B]">Contactez-moi !</h2>
                    <h4 className="text-sm md:text-xl">Intéressé(e) par mon profil ? N'hésitez pas à me contacter !</h4>
                </div>
                <div className="flex-col md:flex-row flex gap-7 m-auto mt-0 min-h-[60vh] h-full items-center">
                    <div className="max-w-2xl md:mr-0 mr-5 ml-5 grid grid-cols-2 md:flex md:flex-col place-content-center gap-2 h-[100%]">
                        <div className="border-2 border-zinc-900 rounded hover:children:cursor-pointer children:items-center hover:bg-gradient-to-r from-[#E6007E] to-[#67348B] duration-700">
                            <div className="p-2 flex gap-2 md:pt-2 pt-5" onClick={() => handleRedirection('mailto:hugobarbieri38@gmail.com')}>
                                <FontAwesomeIcon className="size-7" icon={faEnvelope} />
                                <p className="hidden md:block">hugobarbieri38@gmail.com</p>
                                <p className="block md:hidden">hugobarbieri38 <br />@gmail.com</p>
                            </div>
                        </div>
                        <div className="border-2 border-zinc-900 rounded hover:children:cursor-pointer children:items-center hover:bg-gradient-to-r from-[#E6007E] to-[#67348B] duration-700">
                            <div className="p-2 flex gap-2" onClick={() => handleRedirection('https://maps.app.goo.gl/YJ8exBBUPBRQkLDN8')}>
                                <FontAwesomeIcon className="size-7" icon={faLocationDot} />
                                <p>Grenoble & alentour <br />IUT2 - Grenoble</p>
                            </div>
                        </div>
                        <div className="border-2 border-zinc-900 rounded hover:children:cursor-pointer children:items-center hover:bg-gradient-to-r from-[#E6007E] to-[#67348B] duration-700">
                            <div className="p-2 flex gap-2" onClick={() => handleRedirection('https://github.com/hugo-brb')}>
                                <FontAwesomeIcon className='size-7' icon={faGithub} />
                                <p>Mon Github</p>
                            </div>
                        </div>
                        <div className="border-2 border-zinc-900 rounded hover:children:cursor-pointer children:items-center hover:bg-gradient-to-r from-[#E6007E] to-[#67348B] duration-700">
                            <div className="p-2 flex items-center gap-2 h-[100%]" onClick={() => handleRedirection('https://www.linkedin.com/in/hugo-barbieri-9b37982a1/')}>
                                <FontAwesomeIcon className='size-7' icon={faLinkedin} />
                                <p>Mon Linkedin</p>
                            </div>
                        </div>
                    </div>
                    <div className="mr-5 max-w-2xl md:pl-0 pl-4">
                        <form id="emailForm" encType="text/plain" className="h-[100%] w-[100%] children:p-1 children:bg-zinc-800 children:border-zinc-400" onSubmit={sendEmail}>
                            <input type="text" id="name" name="name" required placeholder="Nom..." className="md:w-[49%] border-2 rounded h-8 mr-1 mb-1 w-[100%]" value={nom} onChange={(e) => setNom(e.target.value)} />
                            <input type="email" id="email" name="email" required placeholder="Mail..." className="md:w-[50.2%] border-2 rounded h-8 mb-1 w-[100%]" value={mail} onChange={(e) => setMail(e.target.value)} />
                            <input type="text" id="objet" name="objet" required placeholder="Objet..." className="w-[100%] border-2 rounded h-8 mt-1 mb-1" value={objet} onChange={(e) => setObjet(e.target.value)} />
                            <input type="text" id="adresse" name="adresse" placeholder="Adresse..." className="w-[100%] border-2 rounded h-8 mt-1 mb-1" value={adresse} onChange={(e) => setAdresse(e.target.value)} />
                            <textarea name="message" id="message" required placeholder="Message..." className="w-[100%] border-2 rounded h-80 mt-1 mb-1 max-h-96 min-h-80 resize-none" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            <input type="submit" className="border-2 rounded-xl bg-zinc-800 text-white p-1 text-sm w-36 h-8 text-center hover:scale-110 duration-300 hover:bg-gradient-to-r from-[#E6007E] to-[#67348B]" value="Envoyer" />
                        </form>
                    </div>
                </div>
                <iframe title="maps" className="border-0 w-11/12 max-w-[650px] h-[400px] m-auto mb-7" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18320.681371369734!2d5.710520700049863!3d45.1885323886042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478af486d4db2145%3A0x5855b4bb6787a494!2sI.U.T.2%20Institut%20Universitaire%20de%20Technologie%20-%20B%C3%A2timent%20Doyen%20Gosse!5e0!3m2!1sfr!2sfr!4v1709890321378!5m2!1sfr!2sfr" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </main>
            <div className="h-1 bg-zinc-700"></div>
        </>
    );
}

export default Contact;
