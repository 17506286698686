import React from "react";

function Lang( {onLangageChange, langage } ){

    //
    const handleLangChange = () => {
        if(langage === 'fr'){  
            onLangageChange('en')
        }else{
            onLangageChange('fr'); 
        }
    }

    //render
    return <>
    <button className=" absolute z-[1010] font-Krungthep text-white top-[5%] md:top-9 2xl:top-[5%] right-16 md:right-4 text-base md:text-sm hover:drop-shadow-[0_0_3px_white] " onClick={handleLangChange}>
        {langage}
    </button>
    </>
}

export default Lang;