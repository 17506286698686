import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faCloudArrowDown, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import cvEn from '../docs/CV-Hugo_BARBIERI_en.pdf';

function Footer({ onPageChange, currentPage }){
    const handleRedirection = (url) => {
        window.open(url, '_blank');
      };

      const handleClick = () => {
        const pdfUrl = cvEn;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "CV_Hugo_BARBIERI_en.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <>
    <footer className=" sm:h-[100%] w-[100%] md:h-[30vh] bg-zinc-900 font-ChakraPetch text-white pt-7 text-base">
        <div className=" hidden md:flex justify-around h-[100%] max-w-[80%] m-auto">
            <div className="flex flex-col gap-2">
                <h4 className='self-center hover:cursor-default'>Quick access</h4>
                <ul className=" text-sm ml-7 list-['↬']">
                    <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'accueil' && 'active'}`} onClick={() => onPageChange('accueil')}>
                        &ensp;Home
                    </li>
                    <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'projets' && 'active'}`} onClick={() => onPageChange('projets')}>
                        &ensp;Projects
                    </li>
                    <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'a-propos' && 'active'}`} onClick={() => onPageChange('a-propos')}>
                        &ensp;About 
                    </li>
                    <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'contact' && 'active'}`} onClick={() => onPageChange('contact')}>
                        &ensp;Contact
                    </li>
                </ul>
            </div>
            <div className=' mt-4 w-0 h-[50%] border-l-2 border-white'></div>
            <div className='flex flex-col items-center gap-7'>
                <h4 className=' self-center hover:cursor-default'>Download my CV in PDF</h4>
                <button className='w-10 h-10 ease-in-out duration-300  hover:scale-125' onClick={handleClick} >
                    <FontAwesomeIcon className=' drop-shadow-[0px_0px_5px_gray] size-12 hover:text-[#67348B] hover:drop-shadow-[0_0_5px_#67348B]' icon={faCloudArrowDown} />
                </button>
                <h4 className=" text-xs mb-5 m-auto hover:cursor-default">@2024-2025 by Hugo BARBIERI</h4>
                
            </div>
            <div className=' mt-4 w-0 h-[50%] border-l-2 border-white'></div>
            <div className='flex flex-col min-w-[20vh] gap-4'>
                <h4 className=' self-center hover:cursor-default'>Networks</h4>
                <div className=' flex gap-2 w-[100%]  hover:children:cursor-pointer '>
                    <FontAwesomeIcon className='duration-300 hover:scale-110 hover:text-[#67348B] size-12' onClick={() => handleRedirection('mailto:hugobarbieri38@gmail.com')} icon={faEnvelope} />
                    <FontAwesomeIcon className='duration-300 hover:scale-110 hover:text-[#67348B] size-12' onClick={() => handleRedirection('https://github.com/hugo-brb')} icon={faGithub} />
                    <FontAwesomeIcon className='duration-300 hover:scale-110 hover:text-[#67348B] size-12' onClick={() => handleRedirection('https://www.linkedin.com/in/hugo-barbieri-9b37982a1/')} icon={faLinkedin} />
                </div>
            </div>
        </div>

        <div className=' sm:flex md:hidden pb-2 flex-col'>
            <div className=' w-[100%] flex justify-center gap-4'>
                <div className="flex flex-col gap-2 w-[30vw]">
                        <h4 className='self-center hover:cursor-default'>Quick access</h4>
                        <ul className=" self-center text-sm ml-7 list-['↬']">
                            <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'accueil' && 'active'}`} onClick={() => onPageChange('accueil')}>
                                &ensp;Home 
                            </li>
                            <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'projets' && 'active'}`} onClick={() => onPageChange('projets')}>
                                &ensp;Projects
                            </li>
                            <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'a-propos' && 'active'}`} onClick={() => onPageChange('a-propos')}>
                                &ensp;About  
                            </li>
                            <li className={`hover:underline hover:underline-offset-2 hover:cursor-pointer hover:text-[#67348B] ${currentPage === 'contact' && 'active'}`} onClick={() => onPageChange('contact')}>
                                &ensp;Contact
                            </li>
                        </ul>
                </div>
                <div className=' mt-3 w-0 h-[90px] border-l-2 border-white'></div>
                <div className='flex flex-col items-center gap-7 w-[30vw]'>
                    <h4 className=' self-center hover:cursor-default'>Download my CV in PDF</h4>
                    <button className='w-10 h-10 ease-in-out duration-300 hover:scale-125' onClick={handleClick} >
                        <FontAwesomeIcon className=' drop-shadow-[0px_0px_5px_gray] size-12 hover:text-[#67348B] hover:drop-shadow-[0_0_5px_#67348B]' icon={faCloudArrowDown} />
                    </button>
                </div>
            </div>
            <div className='flex flex-col items-center min-w-[20vh] gap-4'>
                <h4 className='hover:cursor-default'>Networks</h4>
                <div className=' flex gap-2 hover:children:cursor-pointer '>
                    <FontAwesomeIcon className='duration-300 hover:scale-110 hover:text-[#67348B] size-12' onClick={() => handleRedirection('mailto:hugobarbieri38@gmail.com')} icon={faEnvelope} />
                    <FontAwesomeIcon className='duration-300 hover:scale-110 hover:text-[#67348B] size-12' onClick={() => handleRedirection('https://github.com/hugo-brb')} icon={faGithub} />
                    <FontAwesomeIcon className='duration-300 hover:scale-110 hover:text-[#67348B] size-12' onClick={() => handleRedirection('https://www.linkedin.com/in/hugo-barbieri-9b37982a1/')} icon={faLinkedin} />
                </div>
            </div>
            <h4 className=" ml-[30vw] text-xs mt-4 sm:mx-auto hover:cursor-default">@2024-2025 by Hugo BARBIERI</h4>
            
        
        </div>
        
        
    </footer>
    </>
}

export default Footer;